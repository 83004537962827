import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const CardContainer = styled.section`
  ${tw`relative z-10 grid grid-cols-1 md:grid-cols-2 pt-c120 md:pt-c96 2xl:pt-c120`}
`
const Card = styled.div`
  ${tw`relative flex flex-col justify-center w-full pt-28 md:pt-16 xl:pt-10 px-offset md:px-offsetMd lg:px-16 xl:px-24 pb-c25 md:pb-c20 2xl:pb-c25`}
`
const CardImage = styled.div`
  ${tw`relative flex flex-col justify-center w-full `}
`
const CardHeadline = styled.div`
  ${tw`relative z-10 mt-auto font-black text-center text-white uppercase font-ubuntu`}
`
const CardDescription = styled.div`
  ${tw`relative z-10 mt-auto font-normal text-center text-white sm:w-2/3 md:w-full lg:w-2/3 sm:mx-auto`}
  a {
    ${tw`font-bold underline`}
  }
`
const ImageWrap = styled.div`
  ${tw`w-full h-full `}
`
const Badge = styled.img`
  ${tw`absolute right-0 w-32 bottom-full lg:w-40`}
`

const WpAcfCardsModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfCardsBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `cards-section-${Math.floor(Math.random() * 100)}`

  const length = blockData.cards.length
  return (
    <CardContainer
      id={`${sectionAttributes.anchor || defaultId}`}
      className={`md:grid-rows-${Math.ceil(length / 2)}`}
    >
      {blockData.cards.map((card, i) => {
        if (card === null) return ""
        if (card.type === "image") {
          const image = getImage(card.image?.localFile)
          return (
            <CardImage
              key={`${card.type}-${i}`}
              className={
                card.size === "big"
                  ? "md:row-span-2 2xl:max-h-[840px]"
                  : "2xl:max-h-[420px]"
              }
            >
              <ImageWrap>
                <GatsbyImage
                  className="w-full h-full"
                  image={image}
                  alt={card.image?.altText}
                />
              </ImageWrap>
            </CardImage>
          )
        }
        if (card.type === "text") {
          return (
            <Card
              key={`${card.type}-${i}`}
              className={
                card.size === "big"
                  ? "row-span-2 md:min-h-[600px] 2xl:max-h-[1040px]"
                  : "md:min-h-[300px] 2xl:max-h-[420px] 2xl:h-[420px]"
              }
              style={{
                backgroundColor: card.backgroundColor,
              }}
            >
              <CardHeadline
                className={`headline-card pb-12 xl:pb-0`}
                style={{
                  color: card.headlineColor,
                }}
              >
                <span className="relative">
                  {parse(card.headline)}
                  <span
                    style={{
                      color: card.symbolsColor,
                    }}
                    className="normal-case symbol-code symbol-code__start text-blue"
                  >
                    {card.startSymbol}
                  </span>
                  <span
                    style={{
                      color: card.symbolsColor,
                    }}
                    className="normal-case symbol-code symbol-code__end text-blue"
                  >
                    {card.endSymbol}
                  </span>
                </span>
                {card.badge !== null && (
                  <Badge src={card.badge.localFile?.publicURL} alt="" />
                )}
              </CardHeadline>
              <CardDescription
                className="copy"
                style={{
                  color: card.descriptionColor,
                }}
              >
                {parse(card.description)}
              </CardDescription>
            </Card>
          )
        }
        return ""
      })}
    </CardContainer>
  )
}

WpAcfCardsModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfCardsModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfCardsModuleBlock
